//眼睛检查的所有接口
import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'
import {Loading,MessageBox} from "element-ui"
import da from "element-ui/src/locale/lang/da";
import el from "element-ui/src/locale/lang/el";

function saveContent(type, mode, csid, dataName, name, key='S_ALL_Normal_Save') {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    // console.log(dataName)
    let datas = JSON.parse(JSON.stringify(store.state.eyeTest[dataName]))
    if (!datas[csid]) datas[csid] = store.state.physicianVisits.xzConsulting
    let ExpertId = '00000000-0000-0000-0000-000000000000'
    if (store.state.users.employees.ExpertId) ExpertId = store.state.users.employees.ExpertId
    return axios.post(publicFn.getUrl(key), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: ExpertId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: type,
            S_OP_Model: mode,
            S_OP_Json: publicFn.conversionJson(datas)
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                if (name === '泪膜检查') {
                    store.commit('isbc/uptjk', {
                        val: true,
                        keys: 'lmjc'
                    })
                }
                if (name === '同视机') {
                    store.commit('isbc/uptjk', {
                        val: true,
                        keys: 'tsj'
                    })
                }
                if (name === '视野') {
                    store.commit('isbc/uptjk', {
                        val: true,
                        keys: 'sy'
                    })
                }
                if (name === '像差分析仪') {
                    store.commit('isbc/uptjk', {
                        val: true,
                        keys: 'xcfxy'
                    })
                }
                MessageBox(`${name}保存成功`, '提示')
            }else {
                MessageBox(`${name}保存失败`, '提示')
            }
        })
}

//裂隙灯和眼底检查
function saveLxd(data, name) {
    let alldata =JSON.parse(JSON.stringify(store.state.eyeTest.lxdata))
    console.log(alldata)
    for (let key in data) {
        if (key === 'S_OP_EC_Remark2' || key === 'S_OP_EC_Remark' || key === 'S_OP_EC_Ophthalmoscope') {
            alldata.S_OP_Json[key] = data[key]
        }else {
            alldata.S_OP_Json[key] = data[key]?data[key].join(','):''
        }
    }
    alldata.S_OP_Json = publicFn.conversionJson(alldata.S_OP_Json)
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: alldata
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                MessageBox(`${name}保存成功`, '提示')
                alldata.S_OP_Json = publicFn.decoding(alldata.S_OP_Json)
                store.commit('eyeTest/uplxd', JSON.parse(JSON.stringify(alldata)))
                let objs = store.state.isbc.tjk
                if (name === '裂隙灯检查') {
                    objs.lxd = true
                    store.commit('isbc/uptjk', objs)
                }else {
                    objs.ydjc = true
                    store.commit('isbc/uptjk', objs)
                }
            }else {
                MessageBox(`${name}保存失败`, '提示')
            }
            // console.log(res)
        })
}

//视力检查接口
// 获取视力检查接口PS_all_getList
function getVisionTest() {
   return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
      "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      SearchCondition: {
          S_OP_Type: 'HisInfo',
          ChooseId: store.state.physicianVisits.xzConsulting
      }
   })
}

//获取基础检查的接口PS_all_Normal_getList
function getBaseTest() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'EyesCheck',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}

//获取屈光检查的接口PS_all_Normal_getList
function getRefractive() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'Refractive',
            S_OP_Model: 'S_OP_Refractive180726',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}

//获取试戴的数据接口PS_all_Normal_getList
function getTryD() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'Refractive',
            S_OP_Model: 'S_OP_Try180726',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}

//获取眼压检查接口PS_all_Normal_getList
function getEyeYa() {
    if (!store.state.users.CsUser.CustomerId) {return false}
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_IP',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}

//保存视力检查的接口S_ALL_Normal_Save
function visionTestSave() {
    if (!store.state.users.CsUser.CustomerId) {return false}
    // console.log(store.state.eyeTest.datas)
   return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
       "token": {
           "TimeSpan": 1566827733,
           "sToken": "768DCAFFF869CD61372790C2738012EC"
       },
       o: {
           S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
           S_OP_ExpertId: store.state.users.user.UserId,
           S_OP_CustomerId: store.state.users.CsUser.CustomerId,
           S_OP_Type: 'HisInfo',
           S_OP_Model: 'S_OP_HisInfo180405',
           S_OP_Json: publicFn.conversionJson(store.state.eyeTest.datas)
       }
   })
}


//眼部检查保存接口S_ALL_Normal_Save
function eyeBuTestSave(type) {
    let str = ''
    if (type === 'lxd') {
        str = 'S_OP_EC_part2_isDone'
    }else if (type === 'ydjc') {
       str = 'S_OP_EC_part3_isDone'
    }else  {
        str = 'S_OP_EC_part1_isDone'
    }
    if (!store.state.users.CsUser.CustomerId) {return false}

    store.state.eyeTest.baseTestData[str] = true

    // console.log(store.state.eyeTest.baseTestData)

    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'EyesCheck',
            S_OP_Model: 'S_OP_EyesCheck180405',
            S_OP_Json: publicFn.conversionJson(store.state.eyeTest.baseTestData)
        }
    })
}


//保存屈光检查接口S_ALL_Normal_Save
function RefractiveSave() {
    if (!store.state.users.CsUser.CustomerId) {return false}
    let datas = JSON.parse(JSON.stringify(store.state.eyeTest.refractiveData))
    datas.S_OP_Json =  publicFn.conversionJson(datas.S_OP_Json)
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: datas
    })
}


//保存眼压检查接口S_ALL_Normal_Save
function eyeYaTestSave() {
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'CL_RE_IP',
            S_OP_Model: 'CL_RE_IP180726',
            S_OP_Json: publicFn.conversionJson(store.state.eyeTest.eyeYaTestData)
        }
    })
}



//获取眼底相机接口PS_all_Normal_getList
function getEyeCamera() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_FC',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存眼底相机数据接口S_ALL_Normal_Save
function saveEyeCamera() {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'CL_RE_FC',
            S_OP_Model: 'CL_RE_FC180726',
            S_OP_Json: publicFn.conversionJson(store.state.eyeTest.eyeCamera)
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/uptjk', {
                    val: true,
                    keys: 'ydxj'
                })
                MessageBox('眼底相机保存成功', '提示')
            }else {
                MessageBox('眼底相机保存失败，请稍后再试', '提示')
            }
        })
}


//获取角膜地形图的数据接口PS_all_Normal_getList
function getCorneaMap() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_CTM',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存角膜地形图的数据接口S_ALL_Normal_Save
function saveCorneaMap() {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'CL_RE_CTM',
            S_OP_Model: 'CL_RE_CTM180726',
            S_OP_Json: publicFn.conversionJson(store.state.eyeTest.corneaMap)
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/uptjk', {
                    val: true,
                    keys: 'jmdxt'
                })
                MessageBox('角膜地形图存成功', '提示')
            }else {
                MessageBox('角膜地形图保存失败，请稍后再试', '提示')
            }
        })
}


//获取眼生物测量数据接口PS_all_Normal_getList
function getEyeBiologyTest() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_OM',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存眼生物接口S_ALL_Normal_Save
function saveEyeBiologyTest() {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }

    let datas = store.state.eyeTest.eyeBiologyTest
    // if (datas.CL_RE_OM_OD_OcularAxis && datas.CL_RE_OM_OD_OcularAxis != 0) {
    //   if (Number(datas.CL_RE_OM_OD_OcularAxis) < 14 || Number(datas.CL_RE_OM_OD_OcularAxis) > 40) {
    //      return MessageBox('眼轴数据异常请复查！', '提示')
    //   }
    // }
    // if (datas.CL_RE_OM_OS_OcularAxis && datas.CL_RE_OM_OS_OcularAxis != 0) {
    //     if (Number(datas.CL_RE_OM_OS_OcularAxis) < 14 || Number(datas.CL_RE_OM_OS_OcularAxis) > 40) {
    //         return MessageBox('眼轴数据异常请复查！', '提示')
    //     }
    // }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    if (!datas.CL_RE_OM_ChooseId) datas.CL_RE_OM_ChooseId = store.state.physicianVisits.xzConsulting
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'CL_RE_OM',
            S_OP_Model: 'CL_RE_OM180726',
            S_OP_Json: publicFn.conversionJson(datas)
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/upjzyz', {
                    val: true,
                    keys: 'yswjc'
                })
                store.commit('isbc/uptjk', {
                    val: true,
                    keys: 'yswjc'
                })
                MessageBox('眼生物存成功', '提示')
            }else {
                MessageBox('眼生物保存失败，请稍后再试', '提示')
            }
        })
}


//获取角膜内皮镜数据接口PS_all_Normal_getList
function getCorneaNPJ() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_SM',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存角膜内皮镜数据接口S_ALL_Normal_Save
function saveCorneaNPJ() {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    let datas = store.state.eyeTest.corneaNPJ
    if (!datas.CL_RE_SM_ChooseId) datas.CL_RE_SM_ChooseId = store.state.physicianVisits.xzConsulting
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'CL_RE_SM',
            S_OP_Model: 'CL_RE_SM180726',
            S_OP_Json: publicFn.conversionJson(datas)
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/uptjk', {
                    val: true,
                    keys: 'jpnmj'
                })
                MessageBox('角膜内皮镜保存成功', '提示')
            }else {
                MessageBox('角膜内皮镜保存失败，请稍后再试', '提示')
            }
        })
}


//获取泪膜检查数据接口PS_all_Normal_getList
function getAppearsTest() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_TFE',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存泪膜数据接口S_ALL_Normal_Save
function saveAppearsTest() {
   saveContent('CL_RE_TFE', 'CL_RE_TFE180726', 'CL_RE_TFE_ChooseId', 'appearsTest', '泪膜检查')
}


//同视机接口PS_all_Normal_getList
function getTsj() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_SYN',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存同视机接口数据S_ALL_Normal_Save
function saveTsj() {
    saveContent('CL_RE_SYN', 'CL_RE_SYN180726', 'CL_RE_SYN_ChooseId', 'tsj', '同视机')
}


//获取检查结论数据接口PS_all_Normal_getList
function getTestConclusion() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'Inspection',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}

//获取视野数据接口PS_all_Normal_getList
function getFieldVision() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_VF',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存视野接口数据S_ALL_Normal_Save
function saveFieldVision() {
    saveContent('CL_RE_VF', 'CL_RE_VF180726', 'CL_RE_VF_ChooseId', 'fieldVision', '视野')
}


//获取像差分析仪接口PS_all_Normal_getList
function getAberrationAnalyze() {
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'CL_RE_WI',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}
//保存像差分析仪接口S_ALL_Normal_Save
function saveAberrationAnalyze() {
    saveContent('CL_RE_WI', 'CL_RE_WI180726', 'CL_RE_WI_ChooseId', 'AberrationAnalyze', '像差分析仪')
}


//保存试戴的接口S_all_Normal_SaveList§
function saveTryData() {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    let datas = store.state.eyeTest.tryData
    // console.log(datas)
    // return;

    if (datas.length < 1) {
    }


    datas = datas.map(item => {
        return {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
            S_OP_ID: "00000000-0000-0000-0000-000000000000",
            S_OP_Json: publicFn.conversionJson(item),
            S_OP_Model: "S_OP_Try180726",
            S_OP_Time: "",
            S_OP_Type: "Refractive",
            S_OP_XML: ""
        }
    })


    let items = {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        lo: datas
    }
    axios.post(publicFn.getUrl('S_all_Normal_SaveList'), items)
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/uptjk', {
                    val: true,
                    keys: 'kjsd'
                })
                MessageBox('试戴保存成功', '提示')
            }else {
                MessageBox('试戴保存失败，请稍后再试', '提示')
            }
        })
}

//获取视功能检查的数据
function getViewFnTest() {
    return axios.post(publicFn.getUrl('S_ALL_Normal_Get'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'VisionFunction',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}


//获取导入设备值接口importDeviceData
function getImportEQ(type) {
    return axios.post(publicFn.getUrl('importDeviceData'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            "IndexMin":1,
            "IndexMax":30,
            "RecTimeStart":"",
            "RecTimeEnd":"",
            "RecDeviceType": type,
            "ShopId":store.state.bases.shop.ShopId,
            "bCount":0
        }
    })
}

function saveOCT() {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    return axios.post(publicFn.getUrl('S_ALL_Normal_Save'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            S_OP_ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_ExpertId: store.state.users.user.UserId,
            S_OP_CustomerId: store.state.users.CsUser.CustomerId,
            S_OP_Type: 'CL_RE_CTM',
            S_OP_Model: 'CL_RE_CTM180726',
            S_OP_Json: publicFn.conversionJson(store.state.eyeTest.corneaMap)
        }
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/uptjk', {
                    val: true,
                    keys: 'jmdxt'
                })
                MessageBox('角膜地形图存成功', '提示')
            }else {
                MessageBox('角膜地形图保存失败，请稍后再试', '提示')
            }
        })
}


export default {
    getVisionTest,
    getBaseTest,
    getRefractive,
    getTryD,
    getEyeYa,
    visionTestSave,
    eyeBuTestSave,
    RefractiveSave,
    eyeYaTestSave,
    getEyeCamera,
    getCorneaMap,
    getEyeBiologyTest,
    getCorneaNPJ,
    getAppearsTest,
    getTsj,
    getTestConclusion,
    getFieldVision,
    getAberrationAnalyze,
    saveTryData,
    saveEyeCamera,
    saveCorneaMap,
    saveEyeBiologyTest,
    saveCorneaNPJ,
    saveAppearsTest,
    saveTsj,
    saveFieldVision,
    saveAberrationAnalyze,
    getViewFnTest,
    getImportEQ,
    saveOCT,
    saveLxd
}
