// 专家诊室的所有接口
import store from '@/store/index'
import axios from "@/api/axios"
import publicFn from '@/publicFn/baseFn'
import {Loading,MessageBox} from "element-ui"
import router from '../router/index'
import id from "element-ui/src/locale/lang/id";

//获取专家诊室的总接口PS_all_get
function getExpertClinic() {
    return axios.post(publicFn.getUrl('PS_all_get'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'S_OP_Recomm',
            S_OP_Model: 'S_OP_Recomm200512',
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}


// 保存专家诊室的接口UpdateJSonDictionary
function saveExpertClinic(data, name='眼部诊断') {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }


    if (!data) return false
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    axios.post(publicFn.getUrl('UpdateJSonDictionary'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            ChooseId: store.state.physicianVisits.xzConsulting,
            S_OP_Type: 'S_OP_Recomm',
            S_OP_Model: 'S_OP_Recomm200512',
            CustomerId: store.state.users.CsUser.CustomerId,
        },
        Dictionary: data
    })
        .then(res => {
            loadingInstance.close()
            if (res.UpdateJSonDictionaryResult) {
                store.commit('expertClinic/upisupData', Math.random().toFixed(6).slice(-6))
                MessageBox(`${name}保存成功`, '提示')
            }else {
                MessageBox(`${name}保存失败`, '提示')
            }
        })
}


// 获取处方方案数据接口PS_all_getList
function getViewPlan(type=0) {
    let obj = {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            S_OP_Type: 'Inspection',
            ChooseId: store.state.physicianVisits.xzConsulting,
            "S_OP_Model":"S_OP_IO",
        }
    }
    if (type) obj.isCreatIO = 0
    return axios.post(publicFn.getUrl('PS_all_Normal_getList'), obj)
}

function getKd() {
    return axios.post(publicFn.getUrl('GetPrescriptionTypeList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            ChooseId: store.state.physicianVisits.xzConsulting
        }
    })
}


// 保存处方方案的接口S_ALL_Normal_SaveList
function saveViewPlan(data) {
    if (!store.state.users.CsUser) {
        MessageBox('用户登陆以后才能保存', '提示')
        return;
    }

    if (store.state.expertClinic.isSave !== 'Success') {
        return this.$alert(store.state.expertClinic.isSave, '提示')
            .then(res => {
               router.push({path: '/ifame2', query: {type: 0}})
            })
    }


    let obj =  JSON.parse(JSON.stringify(store.state.expertClinic.viewPlan))

    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });

    // console.log(store.state.expertClinic.expertClinicData.S_OP_Json)

    if (obj.length < 1) {

        return axios.post(publicFn.getUrl('S_OP_ALL_DeleteByType'), {
            "token": {
                "sToken": "45B94332B4508AAE81295EB24C982654",
                "TimeSpan": "1623829629"
            },
            ChooseId: store.state.physicianVisits.xzConsulting,
            "S_OP_Model":"S_OP_IO",
            "S_OP_Type":"Inspection"
        })
            .then(res => {
                loadingInstance.close()
                if (res.msg == "success") {
                    // MessageBox.alert('保存成功!', '提示')
                    let datap = []
                    let obj = store.state.expertClinic.expertClinicData.S_OP_Json
                    datap[0] = {
                        key: 'ObliqueAmblyopia_Solution',
                        value: obj.ObliqueAmblyopia_Solution
                    }
                    datap[1] = {
                        key: 'CL_Solution_SCL',
                        value: obj.CL_Solution_SCL
                    }
                    datap[2] = {
                        key: 'CL_Solution_Frequency',
                        value: obj.CL_Solution_Frequency
                    }
                    datap[3] = {
                        key: 'CL_Solution_OKLens',
                        value: obj.CL_Solution_OKLens
                    }
                    datap[4] = {
                        key: 'CL_Solution_RGP',
                        value: obj.CL_Solution_RGP
                    }
                    datap[5] = {
                        key: 'RecheckTime',
                        value: obj.RecheckTime.indexOf('08:00:00') > -1 ? obj.RecheckTime : obj.RecheckTime + ' 08:00:00'
                    }
                    datap[6] = {
                        key: 'Scene_WearingMethod',
                        value: obj.Scene_WearingMethod
                    }
                    // console.log(obj.RecheckTime)
                    saveExpertClinic(datap, '视光处方方案')
                }else {
                    MessageBox.alert('保存失败!', '提示')
                }
            })
    }


    // console.log(store.state.expertClinic.viewPlan)

    for (let i = 0; i < obj.length; i++) {
        if (obj[i].S_OP_Json.S_OP_IO_DCOD && obj[i].S_OP_Json.S_OP_IO_DCOD != 0) {
            if (!obj[i].S_OP_Json.S_OP_IO_DSOD && obj[i].S_OP_Json.S_OP_IO_DSOD != 0) {
                return MessageBox(`处方${i+1}的OD球镜缺少数据，请填写！`, '提示')
            }
            if (!obj[i].S_OP_Json.S_OP_IO_XOD && obj[i].S_OP_Json.S_OP_IO_XOD != 0) {
                return MessageBox(`处方${i+1}的OD轴向缺少数据，请填写！`, '提示')
            }
        }
        if (obj[i].S_OP_Json.S_OP_IO_DCOS && obj[i].S_OP_Json.S_OP_IO_DCOS != 0) {
            if (!obj[i].S_OP_Json.S_OP_IO_DSOS && obj[i].S_OP_Json.S_OP_IO_DSOS != 0) {
                return MessageBox(`处方${i+1}的OS球镜缺少数据，请填写！`, '提示')
            }
            if (!obj[i].S_OP_Json.S_OP_IO_XOS && obj[i].S_OP_Json.S_OP_IO_XOS != 0) {
                return MessageBox(`处方${i+1}的OS轴向缺少数据，请填写！`, '提示')
            }
        }

        if (obj[i].S_OP_Json.S_OP_IO_XOD && obj[i].S_OP_Json.S_OP_IO_XOD != 0) {
            if (!obj[i].S_OP_Json.S_OP_IO_DSOD && obj[i].S_OP_Json.S_OP_IO_DSOD != 0) {
                return MessageBox(`处方${i+1}的OD球镜缺少数据，请填写！`, '提示')
            }
            if (!obj[i].S_OP_Json.S_OP_IO_DCOD && obj[i].S_OP_Json.S_OP_IO_DCOD != 0) {
                return MessageBox(`处方${i+1}的OD柱镜缺少数据，请填写！`, '提示')
            }
        }
        if (obj[i].S_OP_Json.S_OP_IO_XOS && obj[i].S_OP_Json.S_OP_IO_XOS != 0) {
            if (!obj[i].S_OP_Json.S_OP_IO_DSOS && obj[i].S_OP_Json.S_OP_IO_DSOS != 0) {
                return MessageBox(`处方${i+1}的OS球镜缺少数据，请填写！`, '提示')
            }
            if (!obj[i].S_OP_Json.S_OP_IO_DCOS && obj[i].S_OP_Json.S_OP_IO_DCOS != 0) {
                return MessageBox(`处方${i+1}的OS柱镜缺少数据，请填写！`, '提示')
            }
        }
        obj[i].S_OP_ExpertId = store.state.users.employees?store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000'
        obj[i].S_OP_Json = publicFn.conversionJson(obj[i].S_OP_Json)
    }


    axios.post(publicFn.getUrl('S_all_Normal_SaveList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        lo: obj
    })
        .then(res => {
            loadingInstance.close()
            let datap = []
            let obj = store.state.expertClinic.expertClinicData.S_OP_Json
            datap[0] = {
                key: 'ObliqueAmblyopia_Solution',
                value: obj.ObliqueAmblyopia_Solution
            }
            datap[1] = {
                key: 'CL_Solution_SCL',
                value: obj.CL_Solution_SCL
            }
            datap[2] = {
                key: 'CL_Solution_Frequency',
                value: obj.CL_Solution_Frequency
            }
            datap[3] = {
                key: 'CL_Solution_OKLens',
                value: obj.CL_Solution_OKLens
            }
            datap[4] = {
                key: 'CL_Solution_RGP',
                value: obj.CL_Solution_RGP
            }
            datap[5] = {
                key: 'RecheckTime',
                value: obj.RecheckTime.indexOf('08:00:00') > -1 ? obj.RecheckTime : obj.RecheckTime + ' 08:00:00'
            }
            datap[6] = {
                key: 'Scene_WearingMethod',
                value: obj.Scene_WearingMethod
            }
            // console.log(datap)
            saveExpertClinic(datap, '视光处方方案')
        })
}


//获取医生签名
function getExSignature() {
    return axios.post(publicFn.getUrl('UploadFile_Get'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        SearchCondition: {
            UFReId: store.state.users.employees.ExpertId,
            UFReObject: 'Expert',
            UFType: 'ExpertSign'
        }
    })
}


//医生签名
function ysqm(type='DoctorSign') {
    if (!store.state.expertClinic.qmUrl) {
        return MessageBox('请在手机专家在线设置电子签名！', '提示')
    }
    if (!store.state.physicianVisits.issgqm) {
        return MessageBox('签名已提交，请勿重复提交！', '提示')
    }
    return axios.post(publicFn.getUrl('UploadFile_Insert'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            UFReId: store.state.physicianVisits.xzConsulting,
            "UFReObject": "Choose",
            "UFPath": store.state.expertClinic.qmUrl,
            "UFType": type,
            "UFSaveType":"OSSPrivate"
        }
    })
}

//视光师签名
function sgsqm(type='OptometristSign') {
    if (!store.state.expertClinic.qmUrl) {
        return MessageBox('请在手机专家在线设置电子签名！', '提示')
    }
    // if (!store.state.physicianVisits.issgqm) {
    //     return MessageBox('签名已提交，请勿重复提交！', '提示')
    // }
    return axios.post(publicFn.getUrl('UploadFile_Insert'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: {
            UFReId: store.state.physicianVisits.xzConsulting,
            "UFReObject": "Choose",
            "UFPath": store.state.expertClinic.qmUrl,
            "UFType": type,//视光师签名
            "UFSaveType":"OSSPrivate"
        }
    })
}

//知情同意书医生或者视光师签名
function ZqtysSgin(type, icfid) {
    return axios.post(publicFn.getUrl('UploadFile_ExpertSignToICFSign'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "ExpertId": store.state.users.employees.ExpertId,//具体ExpertId
        "TargetId": store.state.physicianVisits.xzConsulting,//具体知情同意书的Id，即通过上方获取列表接口返回的ICF对象的ICFId
        "SignType": type//即通过上方获取列表接口返回的ICF对象的ICFPicTypeDoctor的值或ICFPicTypeOptometrist的值
    })
}

//知情同意书顾客签名
function ZqtysCsSgin(type, icfid) {
    return axios.post(publicFn.getUrl('UploadFile_CustomerSignToICFSign'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "CustomerId": store.state.users.CsUser.CustomerId,//具体ExpertId
        "TargetId": store.state.physicianVisits.xzConsulting,//具体知情同意书的Id，即通过上方获取列表接口返回的ICF对象的ICFId
        "SignType": type//即通过上方获取列表接口返回的ICF对象的ICFPicTypeDoctor的值或ICFPicTypeOptometrist的值
    })
}


//获取ICD编码
function getICDCODE(str) {
    return axios.post(publicFn.getUrl('ConfigICD_GetList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "ICDName": str
    })
}

//宝视达提交接口
function BSDtj() {
    return axios.post(publicFn.getUrl('BSD_ChooseToBest'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "ChooseId":store.state.physicianVisits.xzConsulting,
        "Type":"frame",
        "ExpertId": store.state.users.employees.ExpertId
    })
}


//获取左右眼拉框
function getODOS() {
  return axios.post(publicFn.getUrl('Product_GetSimpleList'), {
      "token":{
          "TimeSpan":1616479500,
          "sToken":"4D6A372AC044AC7A41331B9141F46536"
      },
      "SearchCondition":{
          "SortKey":"operation",
          "ShopId": store.state.bases.shop.ShopId
      }
  })
}

//获取预约手术
function getOperation(id) {
    let csid = id?id:store.state.physicianVisits.xzConsulting
    return axios.post(publicFn.getUrl('OA_Get'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition": {
            "OAChooseId": csid
        },
        "ReturnInfo":{
            "ExpertName":1
        }
    })
}

// 获取某医生在某日的排号情况
function GetNumberList(exid, times, timee) {
    return axios.post(publicFn.getUrl('OA_GetNumberList'), {
        "token": {
            "TimeSpan": 1616479500,
            "sToken": "4D6A372AC044AC7A41331B9141F46536"
        },
        "SearchCondition": {
            "OAShopId":store.state.bases.shop.ShopId,//门店ShopId
            "OAExpertId": exid,//做手术的医生Id
            "OADateStart":times,//查询的那一天
            "OADateEnd":timee//查询的那一天
        }
    })
}


function getDepartment() {
    return axios.post(publicFn.getUrl('Dept_GetList'), {
        "token": {
            "TimeSpan": 1616479500,
            "sToken": "4D6A372AC044AC7A41331B9141F46536"
        },
        "SearchCondition": {
            "ShopId":store.state.bases.shop.ShopId,//门店ShopId
        }
    })
}


//获取预约手术列表
function getOperationList(zt ="expected") {
    return axios.post(publicFn.getUrl('OA_GetList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition": {
            // "OAChooseId": store.state.physicianVisits.xzConsulting,
            "OAShopId": store.state.bases.shop.ShopId,
            "OAState": zt
        },
        "ReturnInfo":{
            "ExpertName":1,//返回手术医生名
            "AddExpertName":1,//返回操作该预约的人员名
            "CustomerName":1,//返回患者名
            "CustomerGender":1,//返回患者性别
            "CustomerTel":1,//返回患者电话
            "CustomerBirthday":1,//返回患者生日和年龄
        }
    })
}

//保存专家诊室手术预约
function frameDetailAddToCart(data) {
    return axios.post(publicFn.getUrl('OA_Insert'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "o": data
    })
}

//获取专家诊室预约内容
function getChooseList(id, type='operation') {
    return axios.post(publicFn.getUrl('getChooseList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition":{
            "ChooseId": store.state.physicianVisits.xzConsulting,
            "CHPS_OP_ALL_Id": id,
            "CHPProductSortGroup": type
        }
    })
}


// 删除手术预约
function OADeleteById(id) {
    return axios.post(publicFn.getUrl('OA_DeleteById'), {
        "token": {
            "TimeSpan": 1616479500,
            "sToken": "4D6A372AC044AC7A41331B9141F46536"
        },
        "Id": id
    })
}


//获取未预约手术列表
function OAGetListPre(time, zt, lx) {
    if (!time) time = [publicFn.getTime(-30), publicFn.getTime(0)]
    return axios.post(publicFn.getUrl('OA_GetList'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition":{
            "OAShopId": store.state.bases.shop.ShopId,
            "OAState":zt,
            "OADateStart": time[0],//查询日期起点
            "OADateEnd": time[1],//查询日期起点
            OAOperationName: lx
        },
        "ReturnInfo":{
            "ExpertName":1,//返回手术医生名
            "AddExpertName":1,//返回操作该预约的人员名
            "CustomerName":1,//返回患者名
            "CustomerGender":1,//返回患者性别
            "CustomerTel":1,//返回患者电话
            "CustomerBirthday":1,//返回患者生日和年龄
        }
    })

}


//获取手术单个预约内容
function OA_Get(id='') {
    let chosid = id?id:store.state.physicianVisits.xzConsulting
    return axios.post(publicFn.getUrl('OA_Get'), {
        "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition": {
            "OAChooseId": chosid
        },
        "ReturnInfo":{
            "AddExpertName":1,
            "CustomerName":1,//返回患者名
            "CustomerGender":1,//返回患者性别
            "CustomerTel":1,//返回患者电话
            "CustomerBirthday":1,//返回患者生日和年龄
        }
    })
}

export default {
    getExpertClinic,
    saveExpertClinic,
    getViewPlan,
    saveViewPlan,
    getExSignature,
    sgsqm,
    ysqm,
    getKd,
    getICDCODE,
    BSDtj,
    getODOS,
    getOperation,
    GetNumberList,
    getDepartment,
    getOperationList,
    frameDetailAddToCart,
    getChooseList,
    OAGetListPre,
    OADeleteById,
    OA_Get,
    ZqtysSgin,
    ZqtysCsSgin
}
