export default [
    {
        path: '/frameWithHome',
        component: () => import('@/views/frameWith/frameWihtHome')
    },
    {
        path: '/frameWithHome/frwmeDiagnosis',
        name: 'frwmeDiagnosis',
        component: () => import('@/components/frameWith/frwmeDiagnosis')
    },
    {
        path: '/frameWithHome/framePlan',
        name: 'framePlan',
        component: () => import('@/components/frameWith/framePlan')
    },
    {
        path: '/frameWithHome/frameChoose',
        name: 'frameChoose',
        component: () => import('@/views/frameWith/frameChoose')
    },
]